import Style from "../Call/index.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhoneVolume} from "@fortawesome/free-solid-svg-icons";

export default function CallFromKit({onCall}) {
    return (
        <button className={Style.call} onClick={onCall}>
            <FontAwesomeIcon icon={faPhoneVolume} />
        </button>
    )
}
