import axios from "axios";
import {APP_BASE_URL} from "../_config";
import {AUTH_TOKEN} from "../helpers/common";

export const axiosInstance = axios.create({
    baseURL: APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${AUTH_TOKEN}`
    },
})
