import Style from "./index.module.css"
import ChatTime from "../ChatTime";
import {Invoice_Statuses} from "../../constants/common";
import {APP_BASE_URL} from "../../_config";

export default function Invoice({ message }) {

    const link = () => {
        if (message.invoice.status === Invoice_Statuses.PAYED) {
            return <a
                href={`${APP_BASE_URL}/api/v1/invoice/getCheck/${message.invoice.operation_id}`}
                className={Style.link}
            >
                Чек об оплате
            </a>
        }

        if (message.invoice.status === Invoice_Statuses.INVOICE_ISSUED) {
            return <a
                href={message.invoice.operation_url}
                className={Style.link}
            >
                Перейти на страницу оплаты
            </a>
        }

        return ""
    }

    return (
        <div className={Style.container}>
            <div className={Style.invoice}>
                <h3 className={Style.invoiceStatus}>
                    Счет: { message.invoice.status_label }
                </h3>
                <div className={Style.body}>
                    <ul className={Style.invoiceDetails}>
                        <li>ID счета: { message.invoice.invoice_id }</li>
                        <li>Cумма счета: { message.invoice.invoice_amount } &#8376;</li>
                        <li>Дата выставления: { message.invoice.created_at }</li>
                        <li>ID транзакции: { message.invoice.operation_id }</li>
                    </ul>
                    { link() }
                </div>
            </div>
            <ChatTime time={message.time} key={message.time} />
        </div>
    )
}
