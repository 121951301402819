import Style from "./index.module.css"
import InputMask from 'react-input-mask';
import {useState} from "react";
import classNames from "classnames";
import {deleteRequest} from "../../services/delete";
import {axiosInstance} from "../../services/http";

export default function Delete() {
    const [phone, setPhone] = useState("")
    const [name, setName] = useState("")
    const [isSend, setSend] = useState(false)

    const onPhoneChange = (e) => setPhone(e.target.value)
    const onNameChange = (e) => setName(e.target.value)

    const onSubmit = (e) => {
        e.preventDefault();

        setSend(false)

        if (phone.replace(/[^+\d]/g, '').length !== 12) return
        if (name.length < 5) return

        deleteRequest(axiosInstance, { name, phone })

        setSend(true)
        setPhone("")
        setName("")
    }

    return (
        <div className={Style.body}>
            <form className={Style.container} onSubmit={onSubmit}>
                <h2 className={Style.title}>Удаление аккаунта</h2>

                {
                    isSend
                        ? <p className={Style.group__title}>Ваша заявка принята</p>
                        : <>
                            <label className={Style.group}>
                                <span className={Style.group__title}>
                                    Укажите свой номер телефона
                                </span>
                                <InputMask
                                    type="tel"
                                    mask="+7 (999) 999 99 99"
                                    value={phone}
                                    onChange={onPhoneChange}
                                    className={Style.group__input}
                                    placeholder={'+7'}
                                    required
                                />
                            </label>
                            <label className={Style.group}>
                                <span className={Style.group__title}>
                                    Укажите Фамилию и Имя
                                </span>
                                <input
                                    type="text"
                                    className={Style.group__input}
                                    placeholder={'Фамилия Имя'}
                                    required
                                    value={name}
                                    onChange={onNameChange}
                                />
                            </label>
                            <button className={Style.submit} type={"submit"}>
                                Отправить запрос
                            </button>
                        </>
                }

                <p className={Style.text}>
                    Для удаления аккаунта из системы NeedHelp, отправьте номер телефона с помощью которого была
                    выполнена регистрация.
                </p>
                <p className={Style.text}>
                    При совпадении комбинации номера телефона и Фамилии и Имени, аккаунт будет удален.
                </p>
                <br/>
                <p className={Style.text}>Удаляются следующие данные:</p>
                <ul className={classNames({
                    [Style.text]: true,
                    [Style.text__list]: true
                })}>
                    <li>Номер телефона</li>
                    <li>Фамилия</li>
                    <li>Имя</li>
                    <li>Все созданные заявки</li>
                    <li>Вся переписка внутри заявок, включая вложения</li>
                </ul>
            </form>
        </div>
    )
}
