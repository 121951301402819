import {Link} from "react-router-dom";
import AppStyle from "../../assets/App.module.css";
import React from "react";

export default function Footer () {
    return (
        <>
            Разработано{' '}
            <Link
                target="_blank"
                className={AppStyle.linkColor}
                to="https://mars.studio"
            >
                Mars Studio
            </Link>
        </>
    )
}
