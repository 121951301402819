/**
 * Получаем данные о текущем чате
 * @param requestInstance
 * @param chatId
 * @return {*}
 */
export const getChatInfo = async (requestInstance, chatId) => {
    try {
        const response = await requestInstance.get(`/api/v1/chat/info/${chatId}`)
        return response.status === 200 ? response.data : null
    } catch (e) {
        console.error(e)
        return null
    }
}
