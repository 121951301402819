import {createBrowserRouter} from "react-router-dom";
import Chat from "../pages/Chat/index";
import ChatLayout from "../layout/ChatLayout";
import Delete from "../pages/Delete";

export const router = createBrowserRouter([
    {
        path: "chat/",
        element: <ChatLayout />,
        children: [
            {
                path: ":chatID/:userID",
                element: <Chat />,
            },
        ]
    },
    {
        path: 'delete',
        element: <Delete />
    }
]);
