import Style from "./index.module.css"

export default function UiButton ({ text, click, type }) {
    return (
        <button
            className={Style.button}
            onClick={click}
            type={type}
        >
            {text}
        </button>
    )
}

UiButton.defaultProps = {
    click: () => {},
    type: 'submit'
}
