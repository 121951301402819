import Cookies from "js-cookie"

export const AUTH_TOKEN = Cookies.get("auth_token")

export const formattedTime = (time) => {
    if (!time) {
        return ""
    }

    if (time.toString().length <= 10) {
        time *= 1000;
    }

    const date = new Date(time);

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${hours}:${minutes} ${day}.${month}.${year}`;
}
