import InputFiles from 'react-input-files';
import Style from "./index.module.css"
import {useState} from "react";
import {MessagesType} from "../../constants/common";
import classNames from "classnames";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function EnterMessage ({ isDisabled, onSubmitProps }) {
    const MAX_ROWS = 6;
    const TEXTAREA_HEIGHT = 40;

    const [type, setType] = useState(MessagesType.TEXT)
    const [text, setText] = useState('')
    const [files, setFiles] = useState("")
    const [rows, setRow] = useState(1)

    const onChangeText = (e) => {
        setType(MessagesType.TEXT)
        setText(e.target.value);
        if (e.target.value.length === 0) {
            setRow(1)
            return
        }
        const scrollHeight = e.target.scrollHeight
        const rowsCount = Math.ceil(scrollHeight / TEXTAREA_HEIGHT);
        const rowsCnt = rowsCount > MAX_ROWS ? MAX_ROWS : rowsCount
        setRow(rowsCnt)
    }

    const onChangeFile = (files) => {
        setType(MessagesType.FILE)
        setFiles(files)
    }

    const removeFile = (idx) => {
        const list = [...files]
        list.splice(idx, 1)

        if (list.length === 0) {
            setType(MessagesType.TEXT)
            setFiles("")
            return
        }

        setFiles(list)
    }

    const filesName = () => {
        return [...files].map((file, index) => {
            return <div key={index} className={Style.fileList__file}>
                { file.name }
                <button
                    type={'button'}
                    className={Style.fileList__fileRemove}
                    onClick={() => removeFile(index)}
                >
                    <FontAwesomeIcon icon={faClose} width={8} />
                </button>
            </div>
        })
    }

    const submitFile = () => {
        [...files].forEach((file, index) => {
            setTimeout(() => onSubmitProps({
                type: MessagesType.FILE,
                text: "",
                file: file
            }), index * 1000)
        })
    }

    const submitMessage = () => onSubmitProps({type, text})

    const typeFunctions = {
        [MessagesType.FILE]: submitFile,
        [MessagesType.TEXT]: submitMessage
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (isDisabled) {
            return
        }

        typeFunctions[type]()

        setTimeout(() => {
            setType(MessagesType.TEXT)
            setFiles(null)
            setText('');
            setRow(1)
        }, 0)
    }

    return (
        <div className={Style.newMessage}>
            <InputFiles
                onChange={onChangeFile}
                multiple={true}
                accept={'.jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx'}
                disabled={isDisabled}
                style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    background: 'rgb(246,246,249)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#fe6531" d="M17.004 5H9c-1.838 0-3.586.737-4.924 2.076C2.737 8.415 2 10.163 2 12c0 1.838.737 3.586 2.076 4.924C5.414 18.263 7.162 19 9 19h8v-2H9c-1.303 0-2.55-.529-3.51-1.49C4.529 14.55 4 13.303 4 12c0-1.302.529-2.549 1.49-3.51C6.45 7.529 7.697 7 9 7h8V6l.001 1h.003c.79 0 1.539.314 2.109.886c.571.571.886 1.322.887 2.116a2.966 2.966 0 0 1-.884 2.11A2.988 2.988 0 0 1 17 13H9a.99.99 0 0 1-.698-.3A.991.991 0 0 1 8 12c0-.252.11-.507.301-.698A.987.987 0 0 1 9 11h8V9H9c-.79 0-1.541.315-2.114.889C6.314 10.461 6 11.211 6 12s.314 1.54.888 2.114A2.974 2.974 0 0 0 9 15h8.001a4.97 4.97 0 0 0 3.528-1.473a4.967 4.967 0 0 0-.001-7.055A4.95 4.95 0 0 0 17.004 5z"/></svg>
            </InputFiles>

            {
                type === MessagesType.TEXT
                    ? <textarea
                        placeholder={"Type your message..."}
                        className={Style.enterText}
                        onChange={onChangeText}
                        disabled={isDisabled}
                        value={text}
                        autoFocus={true}
                        rows={rows}
                    />
                    : <div className={
                        classNames({
                            [Style.enterText]: true,
                            [Style.fileList]: true
                        })
                    }>
                        {filesName()}
                    </div>

            }

            <button
                type={"submit"}
                className={Style.submit}
                disabled={isDisabled}
                onClick={onSubmit}
            >
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="20px" height="20px" viewBox="0 0 920.000000 920.000000"
                     preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,920.000000) scale(0.100000,-0.100000)"
                       fill="#000000" stroke="none">
                        <path d="M1065 8416 c-119 -38 -214 -119 -263 -224 -23 -48 -27 -70 -27 -142
                            1 -75 6 -97 42 -190 23 -58 112 -294 198 -525 86 -231 176 -472 200 -535 24
                            -63 79 -212 123 -330 169 -455 365 -978 394 -1057 l31 -82 46 -9 c25 -5 1003
                            -169 2173 -363 1171 -195 2128 -356 2128 -359 0 -3 -957 -164 -2128 -359
                            -1170 -194 -2148 -358 -2173 -363 l-46 -9 -31 -82 c-45 -120 -365 -979 -424
                            -1137 -27 -74 -69 -187 -93 -250 -24 -63 -94 -250 -155 -415 -62 -165 -148
                            -397 -192 -515 -78 -207 -81 -218 -81 -300 0 -112 26 -180 98 -258 89 -96 211
                            -139 343 -121 60 9 307 131 2657 1312 528 266 1599 804 2380 1196 1910 960
                            2025 1018 2064 1050 19 15 51 56 70 92 35 61 36 68 36 164 0 90 -3 106 -29
                            160 -17 34 -54 83 -85 114 -51 49 -111 82 -616 336 -2314 1162 -4048 2034
                            -5524 2777 -436 220 -818 408 -849 419 -65 22 -204 25 -267 5z"/>
                    </g>
                </svg>
            </button>
        </div>
    )
}

EnterMessage.defaultProps = {
    isDisabled: false,
    onSubmitProps: () => {}
}
