import Style from "./index.module.css";

export default function UiInput({
    isDisabled,
    inputValue,
    inputLabel,
    inputType,
    isRequired,
    onChange
}) {
    const handleChange = (e) => {
        if (inputType === 'file') {
            const selectedFiles = e.target.files;
            const fileArray = Array.from(selectedFiles);
            onChange(fileArray);
        } else {
            onChange(e.target.value);
        }
    }

    return (
        <div className={Style.formGroup}>
            <label className={Style.fromLabel}>
                {inputLabel}
            </label>
            {
                inputType === "textarea"
                    ?   <textarea
                            disabled={isDisabled}
                            className={Style.formInput}
                            value={inputValue}
                            onChange={handleChange}
                            rows={3}
                            required={isRequired}
                        />
                    : inputType === 'file'
                        ?   <input
                                type={inputType}
                                disabled={isDisabled}
                                className={Style.formInput}
                                onChange={handleChange}
                                required={isRequired}
                                multiple
                            />
                        :   <input
                                type={inputType}
                                disabled={isDisabled}
                                className={Style.formInput}
                                value={inputValue}
                                onChange={handleChange}
                                required={isRequired}
                            />
            }
        </div>
    )
}

UiInput.defaultProps = {
    isDisabled: false,
    isRequired: false,
    inputType: "text",
    inputValue: "",
    onChange: () => {
    }
}
