export const COOKIES_CALL_DATA = '_LYNPdYwh0G_'

export const QuestionStatus = {
    OPEN: 1,
    CLOSE: 2
}

export const MessagesType = {
    TEXT: 'txt',
    FILE: 'file',
    IMAGE: 'img'
}

export const MessageRenderType = {
    MESSAGE: 1,
    ALTER: 2,
    INVOICE: 3
}

/**
 * LAWYER_JOINED - Юрист подключился
 * CLIENT_JOINED - Клиент подключился
 * CLIENT_REJECTED - Клиент отклонил звонок
 * CLIENT_LOST_CONNECTION - Соединение пропало у клиента
 * LAWYERS_LOST_CONNECTION - Соединение пропало у юриста
 * LAWYER_ENDED_CALL - Юрист завершил звонок
 * CLIENT_ENDED_CALL - Клиент завершил звонок
 * DIDNT_ANSWER_CALL - Клиент не ответил на звонок
 */
export const CallStatuses = {
    LAWYER_JOINED: 1,
    CLIENT_JOINED: 2,
    CLIENT_REJECTED: 3,
    CLIENT_LOST_CONNECTION: 4,
    LAWYERS_LOST_CONNECTION: 5,
    LAWYER_ENDED_CALL: 6,
    CLIENT_ENDED_CALL: 7,
    DIDNT_ANSWER_CALL: 8
}

/**
 * Матчинг статуса агоры и внутреннего
 * @type {{"user-left": number, "hangup-refuse": number, "hangup-normal": number, refuse: string, "user-published": number, "user-unpublished": number, "hangup-timeout": number, accept: string}}
 */
export const AGORA_BACKEND_STATUS_COMPARISON = {
    accept: "",
    refuse: "",
    'user-published': CallStatuses.CLIENT_JOINED,
    'user-unpublished': CallStatuses.CLIENT_ENDED_CALL,
    'user-left': CallStatuses.CLIENT_ENDED_CALL,
    'hangup-refuse': CallStatuses.CLIENT_REJECTED,
    'hangup-timeout': CallStatuses.DIDNT_ANSWER_CALL,
    'hangup-normal': CallStatuses.LAWYER_ENDED_CALL
}

/**
 * Статусы инвойсов
 *
 * PAYED - оплачен
 * INVOICE_ISSUED - выставлен
 *
 * @type {{PAYED: number, INVOICE_ISSUED: number}}
 */
export const Invoice_Statuses = {
    INVOICE_ISSUED: 11,
    PAYED: 14
}


/**
 * Роли пользователей
 * @type {{LAWYER: string, ADMIN: string, CLIENT: string}}
 */
export const UserRoles = {
    LAWYER: "lawyer",
    CLIENT: "client",
    ADMIN: "admin"
}
