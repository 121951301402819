import {ChatInfoContext} from "../context/ChatInfoContext";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getChatInfo} from "../services/chat.service";
import {axiosInstance} from "../services/http";
import {UserRoles} from "../constants/common";

export const ChatProvider = ({ children }) => {
    const {chatID} = useParams();

    const [authUser, setAuthUser] = useState({})
    const [responsibleLawyer, setResponsibleLawyer] = useState({})
    const [clientData, setClientData] = useState({})
    const [users, setUsers] = useState([])

    const [questionStatus, setQuestionStatus] = useState(0)
    const [questionServiceId, setQuestionServiceId] = useState(0)

    const [isLoading, setLoading] = useState(true)

    const getInfo = async () => {
        const response = await getChatInfo(axiosInstance, chatID)

        if (!response) {
            return window.location.replace("https://needhelp.kz/ru/")
        }

        setQuestionStatus(response?.status)
        setQuestionServiceId(response?.service_id)

        const authUser = {
            id: response.auth_user.id,
            name: `${response.auth_user.name} ${response.auth_user.surname}`,
            role: response.auth_user.role
        }

        const responsibleLawyer = {
            id: response.lawyer.id,
            name: response.lawyer.fullname,
            role: UserRoles.LAWYER
        }

        const clientData = {
            id: response.client.id,
            name: response.client.fullname,
            role: UserRoles.CLIENT
        }

        const otherLawyers = response.additional_lawyers.map(({lawyer}) => ({
            id: lawyer.id,
            name: `${lawyer.name} ${lawyer.surname}`,
            role: UserRoles.LAWYER
        }))

        setAuthUser(authUser)
        setResponsibleLawyer(responsibleLawyer)
        setClientData(clientData)
        setUsers([
            ...otherLawyers,
            authUser,
            responsibleLawyer,
            clientData,
        ])

        setLoading(false)
    }

    const changeQuestionStatus = (value) => {
        setQuestionStatus(value)
    }

    useEffect(() => {
        getInfo()
    }, [chatID])

    if (isLoading) {
        return (
            <>
                <h1 style={{textAlign: "center", fontWeight: "normal"}}>Loading...</h1>
                <hr/>
            </>
        )
    }

    return (
        <ChatInfoContext.Provider value={
            {
                users,
                chatID: Number(chatID),
                clientData,
                responsibleLawyer,
                currentUser: authUser,
                questionStatus,
                questionServiceId,
                changeQuestionStatus
            }
        }>
            {children}
        </ChatInfoContext.Provider>
    )
}
