import AppStyle from "../../../assets/App.module.css"
import UiInput from "../../ui/Input";
import UiButton from "../../ui/Button";
import {useState} from "react";
import {questionToLegalSupport} from "../../../services/question.service";
import {axiosInstance} from "../../../services/http";

export default function TransferToSupport ({ onSubmitted, questionId, clientFullname }) {
    const [supportTopic, setSupportTopic] = useState("");
    const [supportTopicDescription, setSupportTopicDescription] = useState("");
    const [files, setFile] = useState("");
    const [deadline, setDeadline] = useState("");

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        for (const file of files) {
            formData.append('document', file);
        }

        formData.append('support_topic', supportTopic);
        formData.append('support_topic_desc', supportTopicDescription);
        formData.append('support_deadline', deadline);

        try {
            await questionToLegalSupport(axiosInstance, questionId, formData)
        } catch (e) {
            console.error(e)
        }

        onSubmitted()
    }

    return (
        <form onSubmit={onSubmit}>
            <UiInput
                isDisabled={true}
                inputValue={clientFullname}
                inputLabel={"ФИО клиента"}
            />
            <UiInput
                inputLabel={"Тема сопровождения"}
                isRequired={true}
                inputValue={supportTopic}
                onChange={setSupportTopic}
            />
            <UiInput
                inputType="textarea"
                inputLabel={"Краткое описание сопровождения"}
                isRequired={true}
                inputValue={supportTopicDescription}
                onChange={setSupportTopicDescription}
            />
            <UiInput
                isDisabled={true}
                inputValue={questionId}
                inputLabel={"Номер сопровождения"}
            />
            <UiInput
                inputType="file"
                inputLabel={"Документ"}
                isRequired={false}
                inputValue={files}
                onChange={setFile}
                multiple
            />
            <UiInput
                inputType="date"
                inputLabel={"Срок выполнения услуги"}
                isRequired={true}
                inputValue={deadline}
                onChange={setDeadline}
            />
            <div className={AppStyle.horizontalCenter}>
                <UiButton text="Перевести" />
            </div>
        </form>
    )
}

TransferToSupport.defaultProps = {
    onSubmitted: () => {},
    questionId: null
}
