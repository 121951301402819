import {Outlet} from "react-router-dom";
import {ChatProvider} from "../provider/ChatProvider";

export default function ChatLayout() {
    return (
        <ChatProvider>
            <Outlet />
        </ChatProvider>
    )
}
