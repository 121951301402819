import WebIM from "agora-chat";
import {AGORA_KEY} from "../_config";

WebIM.conn = new WebIM.connection({
    appKey: AGORA_KEY,
    https: true
});

WebIM.conn.addEventHandler('connection', {
    onConnected: () => {
        console.log('login success')
    },
})

document.addEventListener("beforeunload", () => {
    console.log("=========== close connection ==========")
    WebIM.conn.close()
})

export {WebIM}
