import React from "react";
import {QuestionStatus} from "../constants/common";

export const ChatInfoContext = React.createContext(
    {
        users: [],
        chatID: 0,
        clientData: {},
        responsibleLawyer: {},
        currentUser: {},
        questionStatus: QuestionStatus.CLOSE,
        questionServiceId: 0,
        changeQuestionStatus: (value) => ({})
    }
)
