import AgoraRTC from "agora-rtc-sdk-ng";

export const callInstance = AgoraRTC

export const callClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp9" })

/**
 * Получает данные для звонка
 * @param requestInstance
 * @param question_id
 * @return {Promise<*|null>}
 */
export const callConnectionData = async (requestInstance, question_id) => {
    try {
        const response = await requestInstance.post(
            '/api/v1/channels/lawyer/join',
            { question_id }
        )

        return response.status === 200 ? response.data : null
    } catch (e) {
        console.error('callConnectionData', e)
        return null
    }
}

/**
 * Обновление данных звонка
 * @param requestInstance
 * @param callId
 * @param body
 * @return {Promise<null>}
 */
export const callUpdate = async (requestInstance, callId, body) => {
    try {
        const response = await requestInstance.post(`/api/v1/call/update/${callId}`, body)
        return response.status === 200 ? response.data : null
    } catch (e) {
        console.error('callUpdate', e)
        return null
    }
}

/**
 * Отправляем данные о звонке бэку
 * @param requestInstance
 * @param body
 * @return {Promise<void>}
 */
export const sendCallData = async (requestInstance, body) => {
    try {
        const response = await requestInstance.post('/api/v1/agora/sendChannelId', body)
        return response.status === 200 ? response.data : null
    } catch (e) {
        console.error('sendCallData', e)
        return null
    }
}

/**
 * Напоминаем челу о звонке
 * @param requestInstance
 * @param question_id
 * @return {Promise<void>}
 */
export const callReminder = async (requestInstance, question_id) => {
    try {
        const response = await requestInstance.post('/api/v1/call/sms-reminder', { question_id })
        return response.status === 200 ? response.data : null
    } catch (e) {
        //
        console.error('sendCallData', e)
        return null
    }
}

/**
 * Начинаем запись звонка
 * @param requestInstance
 * @param channel
 * @param questionId
 * @return {Promise<*|null>}
 */
export const recordingCall = async (requestInstance, { channel, questionId }) => {
    try {
        const response = await requestInstance.post('/api/v1/recording/start', { channel, questionId })

        if (response.status !== 200) return false;

        const { resourceId, sid } = response.data

        window.callRecordData = { resourceId, sid }

        return true
    } catch (e) {
        return null
    }
}

export const stopRecordingCall = async (requestInstance, channel) => {
    try {
        if (!window.callRecordData) return false;

        const response = await requestInstance.post('/api/v1/recording/stop', {
            channel,
            ...window.callRecordData
        })

        return response.status !== 200
    } catch (e) {
        return null
    }
}
