import {useEffect, useRef} from "react";
import Style from "./index.module.css"
import Message from "../Message/index";
import Invoice from "../Invoice";
import classNames from "classnames";
import {MessageRenderType, MessagesType} from "../../constants/common";
import MessageFile from "../MessageFile";

export default function Messages({ messages, nextPage }) {
    const ref = useRef(null);
    useEffect(() => {
        if (messages.length) {
            ref.current?.scrollIntoView({
                behavior: 'smooth',
                block: "end",
            });
        }
    }, [messages.length]);

    const listItems = messages.map((message) => {
        const key = message.agora_message_id + message.id

        if (message.render_type === MessageRenderType.ALTER) {
            return <p
                key={key}
                className={Style.message}
                dangerouslySetInnerHTML={{ __html: message.message }}
            />
        }

        if (message.render_type === MessageRenderType.INVOICE) {
            return <Invoice key={key} message={message} />
        }

        if (
            message.render_type === MessageRenderType.MESSAGE
            && [MessagesType.FILE, MessagesType.IMAGE].includes(message.type)
        ) {
            return <MessageFile key={key} message={message} />
        }

        return <Message key={key} message={message} />
    });

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop
        if (scrollTop === 0) {
            nextPage()
        }
    }

    const classes = classNames({
        [Style.messages]: true
    })

    return (
        <div className={classes} onScroll={handleScroll}>
            {listItems}
            <div ref={ref} />
        </div>
    )
}

Messages.defaultProps = {
    messages: [],
    nextPage: () => {}
}
