import Style from "./index.module.css"
import ChatTime from "../ChatTime";
import {useContext} from "react";
import {ChatInfoContext} from "../../context/ChatInfoContext";
import {UserRoles} from "../../constants/common";

export default function Message({ message }) {
    const { users, currentUser } = useContext(ChatInfoContext)
    const isCurrentUser = Number(message.from_id) === currentUser.id
    const messageUser = users.find(({id}) => id === message.from_id)

    const messageStyle = isCurrentUser ? Style.outbox : Style.inbox
    const messageTextStyle = isCurrentUser
        ? Style.bgOrange
        : messageUser.role === UserRoles.LAWYER ? Style.bgOrange : Style.bgWhite


    return (
        <div className={`${Style.message} ${messageStyle}` }>
            <p className={`${Style.messageText} ${messageTextStyle}`}>
                {message.message}
            </p>
            <ChatTime
                time={message.time}
                key={message.time}
                text={ isCurrentUser ? '' : ` - ${messageUser.name}` }
            />
        </div>
    )
}
