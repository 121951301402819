/**
 * Запрос за закрытие вопроса
 * @param requestInstance инстранс запроса
 * @param questionId Идентификатор вопроса
 * @return {Promise<boolean>}
 */
export const questionDone = async (requestInstance, questionId) => {
    try {
        await requestInstance.post(`/api/v1/question/to-done/${questionId}`)
        return true
    } catch (e) {
        console.error(e)
        return false
    }
}

/**
 * Запрос на
 * @param requestInstance
 * @param questionId
 * @param formData
 * @return {Promise<boolean>}
 */
export const questionToLegalSupport = async (requestInstance, questionId, formData) => {
    try {
        await requestInstance.post(
            `/api/v1/question/to-legal-support/${questionId}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
        return true
    } catch (e) {
        console.error(e)
        return false
    }
}
