import Style from "./index.module.css"
import ChatTime from "../ChatTime";
import {useContext} from "react";
import {ChatInfoContext} from "../../context/ChatInfoContext";
import {UserRoles} from "../../constants/common";

export default function MessageFile({ message }) {
    const { users, currentUser } = useContext(ChatInfoContext)
    const isCurrentUser = Number(message.from_id) === currentUser.id
    const messageUser = users.find(({id}) => id === message.from_id)

    const attachment = message.attachment

    const messageStyle = isCurrentUser ? Style.outbox : Style.inbox
    const messageTextStyle = isCurrentUser
        ? Style.bgOrange
        : messageUser.role === UserRoles.LAWYER ? Style.bgOrange : Style.bgWhite


    function file() {
        const arr = attachment.filename.split(".")
        const fileExt = arr[arr.length - 1]
        if (['jpeg', 'jpg', 'png', 'svg'].includes(fileExt)) {
            return <a
                href={attachment.path}
                download={attachment.filename}
                target={"_blank"}
                rel="noreferrer"
            >
                <img src={attachment.path} className={Style.attachFile}  alt={'file'}/>
            </a>
        }
        return <>
            <span>{ attachment.filename }</span>
            <a
                className={Style.download}
                href={attachment.path}
                download={attachment.filename}
                target={"_blank"}
                rel="noreferrer"
            >
                <img src={`/download${isCurrentUser ? '' : '-inbox'}.svg`} alt="download" />
            </a>
        </>
    }

    return (
        <div className={`${Style.wrapper} ${messageStyle}` }>
            <div className={`${Style.file} ${messageTextStyle}`}>
                {file()}
            </div>
            <ChatTime
                time={message.time}
                key={message.time}
                text={ isCurrentUser ? '' : ` - ${messageUser.name}` }
            />
        </div>
    )
}
