/**
 * Запрос удаление акканута
 * со страницы /delete
 * @param requestInstance
 * @param body
 * @param body.name
 * @param body.phone
 */
export const deleteRequest = async (requestInstance, body) => {
    try {
        const request = await requestInstance.post('/api/v1/user/deleteRequest', body)
        return request.code
    } catch (e) {
        return 0
    }
}
