import Cookies from "js-cookie"

export const SetCookies = (key, value) => {
    Cookies.set(key, btoa(JSON.stringify(value)))
}

export const GetCookies = (key) => {
    const data = Cookies.get(key)
    if (!data) return null
    return JSON.parse(atob(data))
}
