import {MessagesType} from "../constants/common";

/**
 * Получаем историю сообщений
 * @param requestInstance
 * @param questionId
 * @param pageId
 * @return {Promise<*>}
 */
export const fetchMessages = async (requestInstance, questionId, pageId = 1) => {
    const perPage = 100

    try {
        const messages = await requestInstance.get(
            `/api/v2/chat/messages/${questionId}`,
            {
                params: {
                    chat_page: pageId,
                    per_page: perPage,
                }
            }
        )

        const data = messages?.data

        if (!data) {
            return {}
        }

        const totalElements = data?.total || perPage

        return {
            messagesList: data.data.reverse(),
            totalPages: Math.ceil(totalElements / perPage)
        }
    } catch (e) {
        console.error(e)
        return {
            messagesList: [],
            totalPages: 1
        }
    }
}

/**
 * Запрос за агора токеном
 * @param requestInstance
 * @return {Promise<*|null>}
 */
export const fetchAgoraToken = async (requestInstance) => {
    try {
        const request = await requestInstance.get("/api/v1/chat/token")
        return request?.data || {};
    } catch (e) {
        console.error(e)
        return {}
    }
}

/**
 * Загрузка файла
 * @param requestInstance
 * @param file
 */
export const uploadFile = async (requestInstance, file) => {
    try {
        if (!file) return;

        if (file.size > 10 * 1024 * 1024) {
            alert('Пожалуйста, выберите файл размером не более 10 МБ.');
            return null
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', MessagesType.FILE);

        const response = await requestInstance.post(
            '/api/v1/chat/upload',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                }
            }
        );
        return  response?.data?.file_uuid || null;
    } catch (e) {
        console.error(e)
        return null
    }
}

/**
 * Отправляем сообщение
 * @param requestInstance
 * @param receiverId
 * @param questionId
 * @param messageType
 * @param messageText
 * @param fileUuid
 * @return {Promise<*>}
 */
export const sendMessage = async (
    requestInstance,
    {
        receiverId,
        questionId,
        messageType,
        messageText,
        fileUuid
    }
) => {
    const requestData = {
        to_id: receiverId,
        question_id: questionId,
        type: messageType,
    };

    if (messageType === MessagesType.TEXT && messageText.trim() === '') {
        return;
    }

    if (fileUuid) {
        requestData.file_uuid = fileUuid;
    } else {
        requestData.message = messageText;
    }

    try {
        return await requestInstance.post('/api/v1/chat/send-message', requestData);
    } catch (e) {
        console.error(e)
        return e
    }
}
