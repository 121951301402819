import React, {useContext, useEffect, useState} from 'react'
import 'react-responsive-modal/styles.css';
import Modal from "react-responsive-modal";
import Style from "./index.module.css"
import {fetchAgoraToken, fetchMessages, sendMessage, uploadFile} from "../../services/message.service";
import {axiosInstance} from "../../services/http";
import {WebIM} from "../../services/agoraChat";
import ChatHeader from "../../components/ChatHeader";
import Messages from "../../components/Messages";
import EnterMessage from "../../components/EnterMessage";
import Footer from "../../components/Footer";
import {questionDone} from "../../services/question.service";
import {
    AGORA_BACKEND_STATUS_COMPARISON,
    CallStatuses,
    COOKIES_CALL_DATA,
    MessagesType,
    QuestionStatus, UserRoles
} from "../../constants/common";
import TransferToSupport from "../../components/Modals/TransferToSupport";
import CallFromKit from "../../components/CallFromKit";
import Callkit from "chat-callkit";
import {AGORA_APP_ID} from "../../_config";
import {callConnectionData, callUpdate, recordingCall, sendCallData, stopRecordingCall} from "../../services/agoraCall";
import {GetCookies, SetCookies} from "../../helpers/cookies";
import classNames from "classnames";
import {ChatInfoContext} from "../../context/ChatInfoContext";


export default function Chat() {
    const {
        chatID,
        questionStatus,
        clientData,
        responsibleLawyer,
        currentUser,
        changeQuestionStatus
    } = useContext(ChatInfoContext)

    const [messagePage, setMessagePage] = useState(1);
    const [messagePages, setMessagePages] = useState(0);
    const [messages, setMessages] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [isDisabledMessage, setDisabledMessage] = useState(false);

    const isShowActions = questionStatus === QuestionStatus.OPEN && currentUser.role !== UserRoles.ADMIN

    const messagesRequest = async () => {
        if (messagePages === messagePage) {
            return
        }

        const {messagesList, totalPages} = await fetchMessages(axiosInstance, chatID, messagePage)

        setMessagePages(totalPages)
        setMessagePage(messagePage + 1)
        setMessages(messagePage === 1 ? messagesList : [...messagesList, ...messages])
    }

    const tokenRequest = async (initAgora = false) => {
        const agoraData = await fetchAgoraToken(axiosInstance)

        if (agoraData && initAgora) {
            await agoraInit(agoraData)
        }

        return agoraData?.user_token ?? null
    }

    const refreshToken = async () => {
        const token = await tokenRequest()
        WebIM.conn.renewToken(token)
    }

    const agoraInit = async ({agora_user_uuid, user_token}) => {
        WebIM.conn.agoraUid = agora_user_uuid
        await WebIM.conn.open({
            user: currentUser.id.toString(),
            agoraToken: user_token
        })

        Callkit.init(AGORA_APP_ID, currentUser.id.toString(), WebIM.conn);


        WebIM.conn.addEventHandler(
            'connection&message',
            {
                onTextMessage: handleMessage,
                onImageMessage: handleMessage,
                onFileMessage: handleMessage,
                onTokenWillExpire: refreshToken,
                onTokenExpired: WebIM.conn.onTokenExpired,
                onError: onError
            }
        )
    }

    const handleCallStateChange = async (info) => {
        console.log("=========== handleCallStateChange ===========", info)

        const {type, reson, callInfo} = info

        const {channelName, callId} = GetCookies(COOKIES_CALL_DATA)

        if (type === 'user-unpublished') return

        const isHangup = type === 'hangup'
        const isUserPublished = type === 'user-published'

        if (isUserPublished) {
            recordingCall(
                axiosInstance,
                {
                    channel: channelName,
                    questionId: chatID
                }
            )
        }

        if (isHangup) {
            stopRecordingCall(axiosInstance, channelName)
        }

        const agora_status_id = isHangup
            ? AGORA_BACKEND_STATUS_COMPARISON[`hangup-${reson}`]
            : AGORA_BACKEND_STATUS_COMPARISON[type]

        callUpdate(
            axiosInstance,
            callId,
            {
                agora_status_id: agora_status_id ?? type,
                ...(callInfo && callInfo.duration !== '00:00' && {duration: 'duration'})
            }
        )
    };

    const handleInvite = async (data) => {
        const connectionData = await callConnectionData(axiosInstance, chatID)
        Callkit.answerCall(true, connectionData.user_token)
    }

    const onCall = async () => {
        try {
            const connectionData = await callConnectionData(axiosInstance, chatID)

            const channelName = connectionData.channel_name;
            channelName.replace(/"/g, '');
            const token = connectionData.user_token;
            token.replace(/"/g, '');
            const callId = connectionData.call_id;

            SetCookies(COOKIES_CALL_DATA, {channelName, callId})

            let options = {
                /** The call type:
                 * 0: One-to-one audio call
                 * 1: One-to-one video call
                 * 2: Group video call
                 * 3: Group audio call
                 */
                callType: 0,
                chatType: 'singleChat',
                // callType: 3,
                // chatType: 'groupChat',
                /** The Chat user ID. */
                to: clientData.id.toString(),
                /** The invitation message. */
                message: 'Join me on the call',
                /** The channel name for the call. */
                channel: channelName,
                /** The Agora <Vg k="VSDK" /> token. */
                accessToken: token,
            };

            Callkit.setUserIdMap({ [clientData.id.toString()]: clientData.name });
            Callkit.startCall(options);

            callUpdate(axiosInstance, callId, {agora_status_id: CallStatuses.LAWYER_JOINED})
            sendCallData(
                axiosInstance,
                {
                    channel_id: connectionData.channel_id,
                    question_id: chatID,
                    call_id: callId,
                    lawyer_id: currentUser.id,
                    user_id: clientData.id,
                    channel_name: channelName,
                }
            )
        } catch (e) {
        }
    }

    const onError = (error) => {
        console.error('============================== on error ==============================', error)
    }

    useEffect(() => {
        (async () => {
            await Promise.all([
                messagesRequest(),
                tokenRequest(true),
            ])
        })()
    }, [])

    const nextPage = () => messagesRequest()

    const closeQuestion = () => {
        changeQuestionStatus(QuestionStatus.CLOSE)
    }

    const questionToDone = async () => {
        try {
            await questionDone(axiosInstance, chatID)
            closeQuestion()
        } catch (e) {
            console.error(e)
        }
    }

    const transferredToSupport = () => {
        closeQuestion()
        onCloseModal()
    }

    const onOpenModal = () => setOpenModal(true);
    const onCloseModal = () => setOpenModal(false);

    const onSubmitMessage = async ({type, text, file}) => {
        setDisabledMessage(true)
        let uploadedFile = null
        if (type === MessagesType.FILE) {
            uploadedFile = await uploadFile(axiosInstance, file)

            if (!uploadedFile) {
                return;
            }
        }

        await sendMessage(
            axiosInstance,
            {
                receiverId: clientData.id,
                questionId: chatID,
                messageType: type,
                messageText: text,
                fileUuid: uploadedFile
            }
        )

        setDisabledMessage(false)
    }

    const [currentMessage, setCurrentMessage] = useState("");
    useEffect(() => {
        if (currentMessage) setMessages([...messages, currentMessage])
    }, [currentMessage]);

    const handleMessage = (message) => {
        try {

            if (!message?.id || Number(message.ext.question_id) !== Number(chatID)) return;


            const newMessage = {
                id: null,
                from_id: Number(message.from),
                to_id: Number(message.to),
                type: message.type,
                message: message.msg,
                question_status: message.ext?.question_status,
                agora_message_id: message.id,
                file_uuid: message?.file_uuid ?? null,
                time: message.time,
                render_type: Number(message.ext.render_type),
                attachment: null,
                sender: message?.ext?.sender ?? null ,
                ...(message.ext?.invoice && {invoice: message.ext?.invoice})
            }

            if ([MessagesType.FILE, MessagesType.IMAGE].includes(message.type)) {
                const fileNameFromPath = message.ext.path.split("/")
                const fileName = message?.filename
                    ? message.filename
                    : fileNameFromPath[fileNameFromPath.length - 1]

                const nameArray = fileName.split(".")
                newMessage.attachment = {
                    file_uuid: fileName + message.id,
                    filename: fileName,
                    extension: nameArray[nameArray - 1],
                    path: message.ext.path
                }
            }

            setCurrentMessage(newMessage)
        } catch (e) {
            console.log("handleMessage: ", e)
        }
    }

    return (
        <>
            <div className={Style.chat}>
                <div className={Style.chat__header}>
                    {
                        isShowActions
                            ? <ChatHeader
                                done={questionToDone}
                                transferToSupport={onOpenModal}
                            />
                            : ""
                    }
                    <h3 className={Style.chatTitle}>
                        <span>{clientData.name} - {responsibleLawyer.name}</span>
                        {
                            questionStatus === QuestionStatus.OPEN
                                ? <CallFromKit onCall={onCall}/>
                                : ""
                        }
                    </h3>
                </div>
                <Messages
                    className={Style.chat__content}
                    messages={messages}
                    nextPage={nextPage}
                />
                <div className={ classNames({
                    [Style.chat__footer]: true,
                    [Style.chat__footer_closed]: questionStatus === QuestionStatus.CLOSE
                }) }>
                    {
                        isShowActions
                            ? <EnterMessage
                                isDisabled={isDisabledMessage}
                                onSubmitProps={onSubmitMessage}
                            />
                            : ""
                    }
                    <Footer/>
                </div>
            </div>
            <Modal
                open={openModal}
                onClose={onCloseModal}
                center
                classNames={{
                    modal: Style.chatModal
                }}
            >
                <TransferToSupport
                    onSubmitted={transferredToSupport}
                    questionId={chatID}
                    clientFullname={clientData.name}
                />
            </Modal>
            <Callkit
                agoraUid={WebIM.conn.agoraUid}
                onInvite={handleInvite}
                onStateChange={handleCallStateChange}
                targetUserName={clientData.name}
            />
        </>
    );
}
