import UiButton from "../ui/Button";
import {useContext} from "react";
import {ChatInfoContext} from "../../context/ChatInfoContext";

export default function ChatHeader({ done, transferToSupport }) {
    const { questionServiceId } = useContext(ChatInfoContext)

    return (
        <>
            <UiButton
                text="Завершить заявку"
                click={done}
            />
            {" "}
            {questionServiceId !== 4 && (
                <UiButton
                    text="Перевести в юридическое сопровождение"
                    click={transferToSupport}
                />
            )}
        </>
    )
}
